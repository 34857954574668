/**
 * Our fetch wrapper. This mostly exists to paper over some of the
 * less-opinionated and clunkier parts of fetch itself.
 *
 * Supports middleware (admittedly gross), which allows us to listen
 * globally to a given client for specific responses. We use this
 * currently in order to show the reauthentication dialog when requests
 * begin to 403 for a previously-authenticated user.
 *
 * The wrapper also supports setting "default" headers, so folks writing
 * product code don't need to worry about it (e.g. applying a CSRF
 * token to each of the requests, along with the ability to update it
 * upon a session refresh).
 */
import throttledFetch from './throttler';

const DEFAULT_OPTIONS = {
  mode: 'same-origin',
  credentials: 'same-origin',
  redirect: 'follow',
};

export class HttpClient {
  constructor(options = DEFAULT_OPTIONS, headers = {}) {
    this.options = options;
    this.headers = headers;
    this.middlewares = [];
    this.isSessionValid = true;
  }

  /**
   * Converts json to a FormData object. Arrays and objects are passed into JSON.stringify
   *
   * @param {object} json The json object to convert to a form data instance
   * @return {FormData} An instance of FormData based on the JSON.
   */
  convertJsonToFormData(obj) {
    if (typeof obj !== 'object') {
      throw new Error('convertJsonToFormData expects an object');
    }

    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      let val = obj[key];

      if (typeof obj[key] === 'object') {
        val = JSON.stringify(obj[key]);
      }

      formData.append(key, val);
    });
    return formData;
  }

  /**
   * Replaces any static, globally-applied headers with a new value.
   *
   * @param {string} key The header key
   * @param {string} value The new header value
   */
  setDefaultHeader(key, value) {
    this.headers[key] = value;
  }

  /**
   * Takes n functions to be executed prior to `fetch` resolution.
   * Returns a handle to cleanup any stored functions.
   *
   * @param {function|function[]} fn Middleware function(s)
   * @return {function} A handler to remove added middleware(s)
   */
  addMiddleware(...fn) {
    this.middlewares.push(...fn);
    return () => this.removeMiddleware(...fn);
  }

  /**
   *Takes n functions to be removed from the middleware queue.
   *
   * @param {function|function[]} fn Extant middleware reference(s)
   */
  removeMiddleware(...fn) {
    this.middlewares = this.middlewares.filter(
      (middleware) => !fn.includes(middleware),
    );
  }

  /**
   * Takes a url and an object to be serialized into a querystring.
   * Clobbers the url's query, should one exist.
   *
   * @param {string} url The base url to append the querystring to
   * @param {object} params An object to query-string-ify
   * @return {string} The url with an appended querystring
   */
  query(url, params) {
    const clobberedUrl = url.substring(0, url.indexOf('?')) || url;
    if (!params) {
      console.warn('Expected params to be provided and none were provided');
      return;
    }
    return Object.keys(params)
      .filter((key) => {
        return params[key] !== undefined && params[key] !== null;
      })
      .reduce((string, key, i) => {
        return (
          string +
          (i > 0 ? '&' : '?') +
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent(params[key])
        );
      }, clobberedUrl);
  }

  /**
   * The underlying mechanism to wire `get`, `post`, &c. to `fetch.
   *
   * @param {string} url The target url
   * @param {string} method The HTTP method
   * @param {object} options Additional options to merge as fetch params
   * @returns {Promise<Response>} The fetch promise chain
   */
  createRequest(
    url,
    method,
    { headers: contextSpecificHeaders = {}, body = undefined, ...rest },
  ) {
    // Merge "default" headers (`this.headers`) with context-specific
    const headers = {
      ...this.headers,
      ...contextSpecificHeaders,
    };

    // For older browsers that do not have native fetch support, requests
    // are made using XMLHttpRequest. As a requirement for Dojo's request
    // helpers to work with some views, we have historically patched
    // XMLHttpRequest's open method to inject a CSRF token. When we provide
    // the token at _this_ level (`@mc/networking/http`), it stacks with the
    // one provided by the XHR method, effectively invalidating it...
    //
    // XHR is bizarre in that multiple `setRequestHeader` calls stack on
    // top of one another, ultimately being merged into a single string.
    //
    // e.g. the following expressions:
    //   setRequestHeader('x-foo', 'bar');
    //   setRequestHeader('x-foo', 'bar');
    //
    // would result in the 'x-foo' header being equal to 'bar, bar'.
    //
    // In order to prevent from double-providing the CSRF token, and causing
    // protected endpoints to fail, we have to delete any upstream tokens
    // before they make their way to the patched XMLHttpRequest method.
    //
    // The gating here verifies that fetch has been polyfilled AND the
    // global runtime's `XMLHttpRequest.prototype.open` has been patched.
    if (fetch.polyfill && XMLHttpRequest.hasOpenPatch) {
      delete headers['X-CSRF-Token'];
    }

    let request;

    if (!__TEST__) {
      request = throttledFetch(url, {
        ...this.options,
        ...rest,
        method,
        headers,
        body,
      });
    } else {
      request = fetch(url, {
        ...this.options,
        ...rest,
        method,
        headers,
        body,
      });
    }

    return this.middlewares.reduce((promise, fn) => promise.then(fn), request);
  }

  /**
   * Makes a GET request.
   *
   * @param {string} url
   * @param {object} options
   * @param {boolean} executeOnlyIfSessionValid
   */
  get(
    url,
    options = {},
    executeOnlyIfSessionValid = window.mc_networking_http_client_fix
      ? true
      : false,
  ) {
    if (executeOnlyIfSessionValid && !this.isSessionValid) {
      // Random string we can use to search bugsnag for related errors
      return Promise.reject(
        `The user session has expired l94j96g - GET ${url}`,
      );
    }
    return this.createRequest(url, 'GET', options);
  }

  /**
   * Makes a POST request.
   *
   * @param {string} url
   * @param {object} body
   * @param {object} options
   * @param {boolean} executeOnlyIfSessionValid
   */
  post(
    url,
    body,
    options = {},
    executeOnlyIfSessionValid = window.mc_networking_http_client_fix
      ? true
      : false,
  ) {
    if (executeOnlyIfSessionValid && !this.isSessionValid) {
      // Random string we can use to search bugsnag for related errors
      return Promise.reject(
        `The user session has expired l94j96g - POST ${url}`,
      );
    }
    return this.createRequest(url, 'POST', { ...options, body });
  }

  /**
   * Makes a PUT request.
   *
   * @param {string} url
   * @param {object} body
   * @param {object} options
   */
  put(url, body, options = {}) {
    return this.createRequest(url, 'PUT', { ...options, body });
  }

  /**
   * Makes a PATCH request.
   *
   * @param {string} url
   * @param {object} body
   * @param {object} options
   */
  patch(url, body, options = {}) {
    return this.createRequest(url, 'PATCH', { ...options, body });
  }

  /**
   * Makes a DELETE request.
   *
   * @param {string} url
   * @param {object} options
   */
  delete(url, options = {}) {
    return this.createRequest(url, 'DELETE', options);
  }

  /**
   * Makes a HEAD request.
   *
   * @param {string} url
   * @param {object} options
   */
  head(url, options = {}) {
    return this.createRequest(url, 'HEAD', options);
  }
}

/**
 * A factory to make object construction slightly nicer. Normalizes
 * the two parameter API into object form.
 *
 * @param {object} params.options Persistent options for the HttpClient
 * @param {object} params.headers Persistent headers for the HttpClient
 * @return {HttpClient} A new HttpClient instance
 */
export default function createClient({ options, headers } = {}) {
  return new HttpClient(options, headers);
}
