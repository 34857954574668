export const BREAKPOINTS = {
  PHONE_MAX_WIDTH: 640,
  TABLET_MAX_WIDTH: 1024,
};

export const LABELS = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  PHONE: 'phone',
};
