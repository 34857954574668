import { useEffect } from 'react';

/**
 * Behavior:
 *
 * * When `condition` is true, `className` is added to `document.body`.
 * * When `condition` is false, `className` is removed.
 * * When `className` changes, the old `className` is removed.
 * * When the component using this hook unmounts, `className` is removed.
 *
 * NOTE: If two uses of the hook use the exact same `className`, the first
 * Effect's cleanup will remove the `className`, not the last -- Effects do not
 * mark the `className` for removal. This may result in unexpected behavior.
 *
 * @param {boolean} condition - Determines whether to add the class
 * @param {string} className - The class to add.
 */
export default function useBodyClass(condition, className) {
  useEffect(() => {
    if (condition) {
      document.body.classList.add(className);

      return () => {
        document.body.classList.remove(className);
      };
    }
  }, [condition, className]);
}
