/**
 * ********************
 * *     Important    *
 * ********************
 *
 * The supported localizations in this file are supposted to mirror those within:
 * `app/lib/MC/I18n/SupportedLocale.php`
 *
 * These are the "in-app" supported languages (not to be confused with dot com and login supported languages)
 *
 * Helpful Links:
 * RFC for identifying locales - https://datatracker.ietf.org/doc/html/rfc5646
 * List of locales - https://lingohub.com/developers/supported-locales/language-designators-with-regions
 */

export const FORCE_DEFAULT_MESSAGE_ID = 'force_defaultMessage_id';

// Regex based off of RFC5646 locale identifications
// TODO: This regex is not 100% correct, so going to modify it and use it in the future
// export const LOCALE_REGEX = /^((?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))|((?:([A-Za-z]{2,3}(-(?:[A-Za-z]{3}(-[A-Za-z]{3}){0,2}))?)|[A-Za-z]{4}|[A-Za-z]{5,8})(-(?:[A-Za-z]{4}))?(-(?:[A-Za-z]{2}|[0-9]{3}))?(-(?:[A-Za-z0-9]{5,8}|[0-9][A-Za-z0-9]{3}))*(-(?:[0-9A-WY-Za-wy-z](-[A-Za-z0-9]{2,8})+))*(-(?:x(-[A-Za-z0-9]{1,8})+))?)|(?:x(-[A-Za-z0-9]{1,8})+))/;

// The contents of this object should be a reference to the translation files we support.
export const SUPPORTED_TRANSLATIONS = {
  ENGLISH_US: 'en-us',
  SPANISH_SPAIN: 'es-es',
  PORTUGUESE_BRAZILIAN: 'pt-br',
  FRENCH_FRANCE: 'fr-fr',
  ITALIAN_ITALY: 'it-it',
  GERMAN_GERMANY: 'de-de',
};

/**
 * Locales are region specific adoptions of a language.  They are more specific than a 'language' and thus we may need to map
 * multiple locales to a translation if we do not have a specific locale support.
 */
export const SUPPORTED_LOCALES = {
  DEFAULT: 'en',
  ENGLISH: 'en',
  ENGLISH_US: 'en-us',
  SPANISH: 'es',
  SPANISH_SPAIN: 'es-es',
  PORTUGUESE_BRAZILIAN: 'pt-br',
  FRENCH: 'fr',
  FRENCH_FRANCE: 'fr-fr',
  ITALIAN: 'it',
  ITALIAN_ITALY: 'it-it',
  GERMAN: 'de',
  GERMAN_GERMANY: 'de-de',
};

/**  International date formats based on Intl.DateTimeFormat API. With exception of en-US which we have defaulted to MM-dd-yyyy */
export const SUPPORTED_LOCALES_DATE = [
  { locale: 'en', format: 'MM-dd-yyyy' },
  { locale: 'en', format: 'MM-dd-yyyy' },
  { locale: 'en-us', format: 'MM-dd-yyyy' },
  { locale: 'es', format: 'dd/MM/yyyy' },
  { locale: 'es-es', format: 'dd/MM/yyyy' },
  { locale: 'pt-br', format: 'dd/MM/yyyy' },
  { locale: 'fr', format: 'dd/MM/yyyy' },
  { locale: 'fr-fr', format: 'dd/MM/yyyy' },
  { locale: 'it', format: 'dd/MM/yyyy' },
  { locale: 'it-it', format: 'dd/MM/yyyy' },
  { locale: 'de', format: 'dd.MM.yyyy' },
  { locale: 'de-de', format: 'dd.MM.yyyy' },
  { locale: 'ar', format: 'yyyy/MM/dd' },
];

export const SUPPORTED_LANGUAGES = {
  ENGLISH: 'en',
  SPANISH: 'es',
  PORTUGUESE: 'pt',
  FRENCH: 'fr',
  ITALIAN: 'it',
  GERMAN: 'de',
};

export const LOCALE_TITLES = {
  ENGLISH: 'English',
  SPANISH: 'Español',
  PORTUGUESE: 'Português',
  FRENCH: 'Français',
  ITALIAN: 'Italiano',
  GERMAN: 'Deutsch',
};

export const LANGUAGE_SELECTOR_OPTIONS = {
  [LOCALE_TITLES.ENGLISH]: SUPPORTED_LOCALES.ENGLISH,
  [LOCALE_TITLES.SPANISH]: SUPPORTED_LOCALES.SPANISH,
  [LOCALE_TITLES.FRENCH]: SUPPORTED_LOCALES.FRENCH,
  [LOCALE_TITLES.PORTUGUESE]: SUPPORTED_LOCALES.PORTUGUESE_BRAZILIAN,
  [LOCALE_TITLES.GERMAN]: SUPPORTED_LOCALES.GERMAN,
  [LOCALE_TITLES.ITALIAN]: SUPPORTED_LOCALES.ITALIAN,
};

export const FUTURE_LANGUAGE_SELECTOR_OPTIONS = {
  [LOCALE_TITLES.ENGLISH]: SUPPORTED_LOCALES.ENGLISH,
  [LOCALE_TITLES.SPANISH]: SUPPORTED_LOCALES.SPANISH,
  [LOCALE_TITLES.FRENCH]: SUPPORTED_LOCALES.FRENCH,
  [LOCALE_TITLES.PORTUGUESE]: SUPPORTED_LOCALES.PORTUGUESE_BRAZILIAN,
  [LOCALE_TITLES.GERMAN]: SUPPORTED_LOCALES.GERMAN,
  [LOCALE_TITLES.ITALIAN]: SUPPORTED_LOCALES.ITALIAN,
};

export const LANGUAGE_SELECTOR_LOCALE_GROUPS = {
  [LOCALE_TITLES.ENGLISH]: [
    SUPPORTED_LOCALES.ENGLISH,
    SUPPORTED_LOCALES.ENGLISH_US,
    SUPPORTED_LOCALES.DEFAULT,
  ],
  [LOCALE_TITLES.SPANISH]: [
    SUPPORTED_LOCALES.SPANISH,
    SUPPORTED_LOCALES.SPANISH_SPAIN,
  ],
  [LOCALE_TITLES.PORTUGUESE]: [SUPPORTED_LOCALES.PORTUGUESE_BRAZILIAN],
  [LOCALE_TITLES.FRENCH]: [
    SUPPORTED_LOCALES.FRENCH,
    SUPPORTED_LOCALES.FRENCH_FRANCE,
  ],
  [LOCALE_TITLES.ITALIAN]: [
    SUPPORTED_LOCALES.ITALIAN,
    SUPPORTED_LOCALES.ITALIAN_ITALY,
  ],
  [LOCALE_TITLES.GERMAN]: [
    SUPPORTED_LOCALES.GERMAN,
    SUPPORTED_LOCALES.GERMAN_GERMANY,
  ],
};

/**
 * By having a map of locales linked to supported translations, we can target specific locales and force them to a specific translation
 * This can also be helpful in releasing a new locale and/or debugging.
 */
export const LOCALIZATIONS_MAPPING = {
  [SUPPORTED_LOCALES.ENGLISH]: SUPPORTED_TRANSLATIONS.ENGLISH_US,
  [SUPPORTED_LOCALES.ENGLISH_US]: SUPPORTED_TRANSLATIONS.ENGLISH_US,
  [SUPPORTED_LOCALES.SPANISH]: SUPPORTED_TRANSLATIONS.SPANISH_SPAIN,
  [SUPPORTED_LOCALES.SPANISH_SPAIN]: SUPPORTED_TRANSLATIONS.SPANISH_SPAIN,
  [SUPPORTED_LOCALES.PORTUGUESE_BRAZILIAN]:
    SUPPORTED_TRANSLATIONS.PORTUGUESE_BRAZILIAN,
  [SUPPORTED_LOCALES.FRENCH]: SUPPORTED_TRANSLATIONS.FRENCH_FRANCE,
  [SUPPORTED_LOCALES.FRENCH_FRANCE]: SUPPORTED_TRANSLATIONS.FRENCH_FRANCE,
  [SUPPORTED_LOCALES.ITALIAN]: SUPPORTED_TRANSLATIONS.ITALIAN_ITALY,
  [SUPPORTED_LOCALES.ITALIAN_ITALY]: SUPPORTED_TRANSLATIONS.ITALIAN_ITALY,
  [SUPPORTED_LOCALES.GERMAN]: SUPPORTED_TRANSLATIONS.GERMAN_GERMANY,
  [SUPPORTED_LOCALES.GERMAN_GERMANY]: SUPPORTED_TRANSLATIONS.GERMAN_GERMANY,
};

export const LANGUAGES_MAPPING = {
  [SUPPORTED_LANGUAGES.ENGLISH]: SUPPORTED_TRANSLATIONS.ENGLISH_US,
  [SUPPORTED_LANGUAGES.SPANISH]: SUPPORTED_TRANSLATIONS.SPANISH_SPAIN,
  [SUPPORTED_LANGUAGES.PORTUGUESE]: SUPPORTED_TRANSLATIONS.PORTUGUESE_BRAZILIAN,
  [SUPPORTED_LANGUAGES.FRENCH]: SUPPORTED_TRANSLATIONS.FRENCH_FRANCE,
  [SUPPORTED_LANGUAGES.ITALIAN]: SUPPORTED_TRANSLATIONS.ITALIAN_ITALY,
  [SUPPORTED_LANGUAGES.GERMAN]: SUPPORTED_TRANSLATIONS.GERMAN_GERMANY,
};
