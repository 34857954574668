import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';

/** DESIGN SYSTEM UPDATE
 * TO-DO: This should be moved over to @mc/wink
 */
const Portal = ({
  children,
  className = 'portal-root',
  mountNode,
  portalStack,
}) => {
  return createPortal(
    <div className={className} data-portal-stack={portalStack}>
      {children}
    </div>,
    mountNode ? mountNode() || document.body : document.body,
  );
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mountNode: PropTypes.func,
  portalStack: PropTypes.string,
  prependNode: PropTypes.bool,
};

export default Portal;
