import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClusterLayout, Input } from '@mc/wink';
import HelpTextLabel from './HelpTextLabel';
import styles from './fields.less';
import ErrorMessage from './ErrorMessage';

const DatePartInput = ({ datePart, value, onChange }) => {
  const placeholder = datePart;
  let className = '';
  let label = '';

  switch (datePart) {
    case 'MM':
      className = styles.monthInput;
      label = 'Month';
      break;
    case 'DD':
      className = styles.dayInput;
      label = 'Day';
      break;
    case 'YYYY':
      className = styles.yearInput;
      label = 'Year';
      break;
    default:
      throw new Error('Unknown date-part value');
  }

  return (
    <Input
      type="number"
      label={label}
      hideLabel={true}
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
    />
  );
};

DatePartInput.propTypes = {
  datePart: PropTypes.oneOf(['MM', 'DD', 'YYYY']),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const DateField = ({
  dateFormat,
  field,
  label,
  error,
  value,
  onChange,
  textClassName,
  name,
}) => {
  const initialValues = {};
  dateFormat.split('/').forEach((dateFormatString) => {
    initialValues[dateFormatString] = value ? value[dateFormatString] : null;
  });

  const [dateValues, setDateValues] = useState(initialValues);

  return (
    <div>
      <label className={styles.nonDefaultLabel} htmlFor={name}>
        {label}
      </label>

      {dateFormat && (
        <ClusterLayout>
          {dateFormat.split('/').map((datePart) => {
            return (
              <DatePartInput
                datePart={datePart}
                name={datePart}
                key={datePart}
                value={initialValues[datePart] || ''}
                onChange={(partValue) => {
                  const newDatesValue = {
                    ...dateValues,
                    [datePart]: partValue,
                  };
                  setDateValues(newDatesValue);
                  onChange(newDatesValue);
                }}
                textClassName={textClassName}
              />
            );
          })}
        </ClusterLayout>
      )}

      {error && <ErrorMessage error={error} />}

      {field.helpTextEnabled && field.helpText && (
        <HelpTextLabel helpText={field.helpText} className={textClassName} />
      )}
    </div>
  );
};

DateField.propTypes = {
  dateFormat: PropTypes.oneOf(['MM/DD/YYYY', 'DD/MM/YYYY', 'MM/DD', 'DD/MM'])
    .isRequired,
  error: PropTypes.string,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  textClassName: PropTypes.string,
  value: PropTypes.shape({
    DD: PropTypes.string,
    MM: PropTypes.string,
    YYYY: PropTypes.string,
  }),
};

export default DateField;
