/**
 * This module provides the public interface for HttpClient. By default,
 * we construct a "default" instance in this file for consumption across
 * the application.
 *
 * We have a "default" instance so we can apply some global middleware, as
 * well as apply some Avesta-required headers.
 *
 * As an escape, we also export a factory for any one-off instances
 * in which one might need to construct a private, scoped HTTP client.
 */

import createClient from './client';

/**
 * Wrapper for creating a new `HttpClient` with the provided config.
 *
 * **!WARNING!** Nearly every use case should be using the instantiated
 * client exported as this module's default rather than creating a
 * custom one. The default client is preconfigured to play well with Avesta.
 *
 * @param {{ options: object, headers: object }} param0
 */
export { createClient };

/**
 * Default `HttpClient` instance, wrapping `fetch`
 *
 * Supports `get`, `post`, `put`, `patch`, `delete`, `head`–
 * each method returns a promise, fulfilled with a fetch `Response`
 *
 * Example:
 *
 *     client
 *       .get(client.query('/my-endpoint', { id: 1 }))
 *       .then(response => response.json())
 *       .then(data => console.log(data))
 *
 */
export default createClient({
  headers: {
    // This first header is required for Avesta's +requireAuth()+ logic.
    // If present, it will return 403s when appropriate. Without, the client
    // is redirected to a login screen instead.
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
});
