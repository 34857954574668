import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import sessionStorage from '@mc/storage/session';

const STAGES = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

/**
 * By default we don't send bugsnags from dev. Enable overriding this behavior based on
 * a session storage key value pair. Engineers can toggle this value temporarily within
 * the settings pane of the admin tool bar.
 *
 * @returns array
 */
const getEnabledStages = () => {
  const stages = [STAGES.PRODUCTION];

  if (__DEV__) {
    if (sessionStorage.getItem('dev-tools:enable-bugsnag-dev') === 'true') {
      stages.push(STAGES.DEVELOPMENT);
    }
  }

  return stages;
};

function getReactApiKey() {
  if (window.bugsnag_react_contextual_key) {
    return window.bugsnag_react_contextual_key;
  }

  // Fall back to production key
  return 'a1c333e00bcc2a5bb56a9dd575fe9813';
}

function addFullStoryMetadata(event) {
  // Make sure FullStory object exists.
  try {
    if (window.FS && window.FS?.getCurrentSessionURL) {
      event.addMetadata('fullstory', {
        url: window.FS.getCurrentSessionURL(true),
      });
    }
  } catch (e) {
    console.error('Fullstory session URL not fetchable', e);
  }
}

function configureBugsnag(config) {
  // Make sure Bugsnag exists
  try {
    Bugsnag.addOnError((event) => {
      event.app.version = config.appVersion;
      event.setUser(
        config.bugsnag.user.id,
        undefined,
        config.bugsnag.user.name,
      );
      event.addMetadata('userInfo', {
        mcadmin: config.bugsnag.user.mcadmin,
        role: config.bugsnag.user.role,
        type: config.bugsnag.user.type,
      });
    });
  } catch (e) {
    Bugsnag.notify(
      new Error('@mc/config not available while trying to configure Bugsnag'),
    );
  }
}

function getNetworkData() {
  if (window.navigator.connection) {
    const { connection } = window.navigator;
    const { downlink, effectiveType, rtt, type } = connection;
    return { downlink, effectiveType, rtt, type };
  }
}

function configureBugsnagPerformance(config) {
  try {
    if (!__DEV__ && !window.xp_bugsnag_performance_force_sample) {
      const networkData = getNetworkData();
      BugsnagPerformance.start({
        apiKey: getReactApiKey(),
        appVersion: config.appVersion,
        releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
        onSpanEnd: [
          (span) => {
            span.setAttribute('user.userId', config.bugsnag.user.id);
            span.setAttribute('user.locale', config.supportL10nData.locale);
            span.setAttribute('user.plan', window.gtmData?.plan || false);
            span.setAttribute(
              'user.country',
              window.otStubData?.userLocation?.country || '',
            );
            if (window.FS && window.FS?.getCurrentSessionURL) {
              span.setAttribute(
                'user.fullstory',
                window.FS.getCurrentSessionURL(true),
              );
            }
            if (networkData) {
              span.setAttribute('network.downlink', networkData.downlink);
              span.setAttribute(
                'network.effectiveType',
                networkData.effectiveType,
              );
              span.setAttribute('network.rtt', networkData.rtt);
              span.setAttribute('network.type', networkData.type);
            }
            span.setAttribute(
              'navigation.type',
              window.O11yRUM?.rumReporter?.tags?.navigationType || '',
            );
            span.setAttribute(
              'navigation.referrer',
              window.navigation?.activation?.from?.url || '',
            );
            return true;
          },
        ],
        networkRequestCallback: (requestInfo) => {
          // These qualtrics network requests are too excessive and use up most of our span quota
          if (requestInfo.url.includes('qualtrics.com')) {
            return null;
          }
          return requestInfo;
        },
      });
    } else if (window.xp_bugsnag_performance_force_sample) {
      const networkData = getNetworkData();
      BugsnagPerformance.start({
        apiKey: getReactApiKey(),
        appVersion: config.appVersion,
        releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
        samplingProbability: 1.0,
        onSpanEnd: [
          (span) => {
            span.setAttribute('user.userId', config.bugsnag.user.id);
            span.setAttribute('user.locale', config.supportL10nData.locale);
            span.setAttribute('user.plan', window.gtmData?.plan || false);
            span.setAttribute(
              'user.country',
              window.otStubData?.userLocation?.country || '',
            );
            if (window.FS && window.FS?.getCurrentSessionURL) {
              span.setAttribute(
                'user.fullstory',
                window.FS.getCurrentSessionURL(true),
              );
            }
            if (networkData) {
              span.setAttribute('network.downlink', networkData.downlink);
              span.setAttribute(
                'network.effectiveType',
                networkData.effectiveType,
              );
              span.setAttribute('network.rtt', networkData.rtt);
              span.setAttribute('network.type', networkData.type);
            }
            span.setAttribute(
              'navigation.type',
              window.O11yRUM?.rumReporter?.tags?.navigationType || '',
            );
            span.setAttribute(
              'navigation.referrer',
              window.navigation?.activation?.from?.url || '',
            );
            return true;
          },
        ],
        networkRequestCallback: (requestInfo) => {
          // These qualtrics network requests are too excessive and use up most of our span quota
          if (requestInfo.url.includes('qualtrics.com')) {
            return null;
          }
          return requestInfo;
        },
      });
    }
  } catch (e) {
    Bugsnag.notify(
      new Error('@mc/config not available while trying to configure Bugsnag'),
    );
  }
}

Bugsnag.start({
  apiKey: getReactApiKey(),
  autoTrackSessions: false,
  autoDetectErrors: true,
  enabledReleaseStages: getEnabledStages(),
  releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    addFullStoryMetadata(event);
  },
});

if (!window.xp_bugsnag_performance_custom_attributes) {
  BugsnagPerformance.start({
    apiKey: getReactApiKey(),
    releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
  });
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export {
  ErrorBoundary as default,
  Bugsnag,
  configureBugsnag,
  configureBugsnagPerformance,
};
