import { useMemo } from 'react';

function isFunction(value) {
  return !!(value && {}.toString.call(value) === '[object Function]');
}

function assignRef(ref, value) {
  if (ref === null || ref === undefined) {
    return;
  }
  if (isFunction(ref)) {
    ref(value);
  } else {
    try {
      ref.current = value;
    } catch (error) {
      throw new Error(`Cannot assign value "${value}" to ref "${ref}"`);
    }
  }
}

/**
 * Keeps multiple refs updated with returned ref.
 *
 * From reach-ui, renamed from useForkedRef.
 *
 * Example:
 * ```
 * const localRef = useRef();
 * const joinedRef = useJoinedRef(forwardedRef, localRef);
 *
 * function handleChange() {
 *   const { top } = localRef.current.getBoundingClientRect();
 * }
 *
 * <Component ref={joinedRef} />
 * ```
 *
 * @param  {...any} refs refs to join
 */
function useJoinedRef(...refs) {
  return useMemo(() => {
    if (refs.every((ref) => ref === null || ref === undefined)) {
      return null;
    }
    return (node) => {
      refs.forEach((ref) => {
        assignRef(ref, node);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

export default useJoinedRef;
