/**
 * This is intended only for INTLX Team
 * By design, this requires the page to have a script
 * tag of type "application/json" with the id of "js-config".
 *
 * All data contained should be considered static and purely for application
 * hydration.
 */

import freeze from '@mc/fn/freeze';

const translationId = 'js-translation-data';

const readTranslations = () => {
  let data = {};
  const configElement = document.getElementById(translationId);

  try {
    data = JSON.parse(configElement.textContent);
    return freeze(data);
  } catch (error) {
    if (__DEV__) {
      throw new Error(
        '@mc/translation-data: Expected JSON, received: ' +
          configElement.textContent,
      );
    }
  }
};

export default readTranslations;
