import { useState, useEffect } from 'react';

const reducedMotionMQ = window.matchMedia('(prefers-reduced-motion: reduce)');

/**
 * Returns whether REDUCED motion is requested by the user.
 *
 * @returns {boolean}
 */
export default function usePrefersReducedMotion() {
  const [isMotionReduced, setIsMotionReduced] = useState(
    reducedMotionMQ.matches,
  );

  useEffect(() => {
    //Check for reduced motion and disable animations if we need to
    const checkMotion = () => {
      setIsMotionReduced(reducedMotionMQ.matches);
    };

    if (reducedMotionMQ?.addEventListener) {
      reducedMotionMQ.addEventListener('change', checkMotion);
      return () => {
        reducedMotionMQ.removeEventListener('change', checkMotion);
      };
    } else if (reducedMotionMQ?.addListener) {
      reducedMotionMQ.addListener(checkMotion);
      return () => {
        reducedMotionMQ.removeListener(checkMotion);
      };
    }
    return () => undefined;
  }, []);

  return isMotionReduced;
}
