import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@mc/wink';
import HelpTextLabel from './HelpTextLabel';

import styles from './fields.less';

/**
 * A base component to build form fields that have a text input
 */
const TextField = ({
  field,
  label,
  // only gets passed when being rendered by a public-facing form
  propsFromForm = {},
  textClassName,
  prefixText,
  ...props
}) => {
  return (
    <div>
      {!props.hideLabel && (
        <label
          aria-hidden="true"
          className={styles.nonDefaultLabel}
          htmlFor={propsFromForm.name}
        >
          {label || propsFromForm.label}
        </label>
      )}
      <Input
        prefixText={prefixText}
        hideLabel={true}
        label={label}
        {...propsFromForm}
        {...props}
        id={propsFromForm.name}
      />
      {field.helpTextEnabled && field.helpText && (
        <HelpTextLabel helpText={field.helpText} className={textClassName} />
      )}
    </div>
  );
};

TextField.propTypes = {
  field: PropTypes.object,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  prefixText: PropTypes.node,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default TextField;
