import { colord } from 'colord';
/**
 * Based off of Case Andberg's toState function in react-color:
 * https://github.com/casesandberg/react-color/blob/bc9a0e1dc5d11b06c511a8e02a95bd85c7129f4b/src/helpers/color.js
 *
 * @param {Object|String} data color format to be converted
 * @return {Object} object that returns initial data value in formats: hex, hsl, hsv, and rgb
 */
export default function convertColors(data) {
  // Maintain legacy support for react-color's use of 'transparent' as a
  // valid color value.
  const isTransparent = data === 'transparent';
  const inputColor = isTransparent ? { r: 0, g: 0, b: 0, a: 0 } : data;

  const color = colord(inputColor);
  const hsl = color.toHsl();
  const hsv = color.toHsv();
  const rgb = color.toRgb(); // Output includes alpha value
  const rgbString = color.toRgbString();
  const hex = isTransparent ? '' : color.toHex(); // Output includes hash

  return {
    hsl,
    hex,
    rgb,
    rgbString,
    hsv,
  };
}
