import { useState, useEffect } from 'react';

import { BREAKPOINTS } from '@mc/constants/breakpoints';

const PHONE = 0;
const TABLET = 1;
const DESKTOP = 2;

const phoneMql = window.matchMedia(
  `(max-width: ${BREAKPOINTS.PHONE_MAX_WIDTH}px)`,
);

const tabletMql = window.matchMedia(
  `(min-width: ${BREAKPOINTS.PHONE_MAX_WIDTH + 1}px) and (max-width: ${
    BREAKPOINTS.TABLET_MAX_WIDTH
  }px)`,
);

const desktopMql = window.matchMedia(
  `(min-width: ${BREAKPOINTS.TABLET_MAX_WIDTH + 1}px)`,
);

/**
 * A function which gets the current breakpoint variant.
 * Used only in this module as the lazy initial state function.
 */
function getInitialVariant() {
  if (phoneMql.matches) {
    return PHONE;
  } else if (tabletMql.matches) {
    return TABLET;
  }
  return DESKTOP;
}

/**
 * @typedef {Object} Breakpoints
 * @property {boolean} isPhone - Whether the phone breakpoint is reached
 * @property {boolean} isTablet - Whether the tablet breakpoint is reached
 * @property {boolean} isDesktop - Whether the desktop breakpoint is reached
 */

/**
 * A hook which returns an enum value indicating the current matched
 * breakpoint. This module exports the enum values for the three variants
 * (PHONE, TABLET, DESKTOP), so consumers of this hook can reference the
 * matched value accordingly.
 *
 * The hook also adds listeners to two, module-scoped MediaQueryLists
 * and handles cleanup on unmount.
 *
 * @deprecated useBreakpoints has been deprecated. Please use the useWinkBreakpoints hook from "@mc/hooks/useWinkBreakpoints"
 * @return {Breakpoints} An object of boolean properties, e.g. `isPhone`
 */
export default function useBreakpoints() {
  const [variant, setVariant] = useState(getInitialVariant);

  useEffect(() => {
    function handlePhone(e) {
      if (e.matches) {
        setVariant(PHONE);
      }
    }

    function handleTablet(e) {
      if (e.matches) {
        setVariant(TABLET);
      }
    }

    function handleDesktop(e) {
      if (e.matches) {
        setVariant(DESKTOP);
      }
    }

    phoneMql.addListener(handlePhone);
    tabletMql.addListener(handleTablet);
    desktopMql.addListener(handleDesktop);

    return () => {
      phoneMql.removeListener(handlePhone);
      tabletMql.removeListener(handleTablet);
      desktopMql.removeListener(handleDesktop);
    };
  }, []);

  return {
    isPhone: variant === PHONE,
    isTablet: variant === TABLET,
    isDesktop: variant === DESKTOP,
  };
}
