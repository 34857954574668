/**
 * Translate value in bytes to a human-readable string with units
 * Uses base 10 multiplier of 1000
 * Ex: input of 2,500 will return "2.5kb"
 *
 * @param {int} bytes A numerical value to convert
 * @return {String} A string with an adjusted numerical value and unit
 */
function humanizeBytes(bytes) {
  // return null if bytes is falsy
  if (!bytes) {
    return null;
  }

  const multiplier = 1000;
  const kilo = multiplier;
  const mega = kilo * multiplier;
  const giga = mega * multiplier;
  if (bytes >= giga) {
    return `${(bytes / giga).toFixed(1)}gb`;
  } else if (bytes >= mega) {
    return `${(bytes / mega).toFixed(1)}mb`;
  } else if (bytes >= kilo) {
    return `${(bytes / kilo).toFixed(1)}kb`;
  }
  return `${bytes}b`;
}

export default humanizeBytes;
