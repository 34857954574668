import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from '@mc/wink';
import fieldLabel from '../../Field/BaseComponent/fieldLabel';
import Email from '../../Field/MergeField/Email';
import MergeField from '../../Field/MergeField';
import Message from './Message';
import Subject from './Subject';

function getInputByType(field, destination, propsFromForm) {
  // TODO: Temporary until we need to wire this up
  const textClassName = 'contact-form-field';
  switch (field.destination.type) {
    case 'email':
      return (
        <Email
          field={field}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
        />
      );
    case 'contact_form_field':
      switch (field.destination.id) {
        case 'message':
          return (
            <Message
              field={field}
              destination={destination}
              propsFromForm={propsFromForm}
              textClassName={textClassName}
            />
          );
        case 'subject':
          return (
            <Subject
              field={field}
              propsFromForm={propsFromForm}
              textClassName={textClassName}
            />
          );
        default:
          throw new Error('Unexpected contact_form_field type');
      }
    case 'merge_field':
      return (
        <MergeField
          destination={destination}
          field={field}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
        />
      );
    case 'group':
      return;
    // return <Group {...props} />;
    default:
      throw new Error('Unexpected input type');
  }
}

const Field = ({ field, destination }) => {
  return (
    <FormField
      name={`fields.${field.id}`}
      component={(propsFromForm) => {
        return getInputByType(field, destination, propsFromForm);
      }}
      label={fieldLabel(field, destination)}
    />
  );
};

Field.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default Field;
