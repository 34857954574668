import { createContext } from 'react';

/**
 * These are the separate contexts the editing platform uses
 * to provide controls with the appropriate data and functions.
 * Provide defaults here only when the context outside of the
 * EditingContainer needs a value. Otherwise, context values
 * are set in the EditingContainer/Providers file that
 * matches the name of each of these respective context below.
 *
 * e.g. Coordination -> EditingContainer/Providers/Coordination.js
 */
export const Coordination = createContext({});
export const MouseCoordinates = createContext({});
export const Drag = createContext({});
export const Globals = createContext({});
export const HoveredBlock = createContext({});
export const SelectedBlock = createContext({});
export const FrameDocument = createContext(null);
export const FrameNode = createContext(null);
export const RichText = createContext({ isStaticRender: true });
export const ChangeHistory = createContext({});
export const BlockManagement = createContext({});
export const SaveStatus = createContext({});
export const Device = createContext({
  isMobileWeb: false,
  isMobileNative: false,
  isTouchDevice: false,
});
export const TemplateSelection = createContext({});
export const Capabilities = createContext({});
export const Document = createContext({});
export const FormSettings = createContext({});
export const Tracking = createContext({});
export const LinkOptions = createContext({});
