import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './fields.less';

const FieldContainer = ({ children }) => {
  return (
    <div className={cx(`FormFieldManagerField`, styles.fieldWrapper)}>
      {children}
    </div>
  );
};

FieldContainer.propTypes = {
  children: PropTypes.node,
};

export default FieldContainer;
