import client from '@mc/networking/http';

export function postForm({ userId, formId, formData }) {
  const url = client.query('/contact-form/post', {
    u: userId,
    form_id: formId,
  });

  return client.post(url, JSON.stringify(formData)).then(async (response) => {
    if (response.ok || response.status === 400) {
      return response.json();
    }
    return Promise.reject(
      new Error(`Error submitting contact form`, {
        cause: await response.json(),
      }),
    );
  });
}
