import { useEffect, useRef } from 'react';

const useUpdateEffect = (fn, deps) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      return fn();
    }
    isMounted.current = true;

    // We don't expect upstream usages of `effect` to be memoized, because
    // upstream usages of this hook are expected to have exhaustive coverage
    // of dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
