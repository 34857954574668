export function getNestedValue(obj, path) {
  if (typeof path === 'string') {
    return getNestedValue(obj, path.split('.'));
  }

  if (obj === undefined) {
    return undefined;
  }

  const [key, ...remaining] = path;
  if (key) {
    return getNestedValue(obj[key], remaining);
  }

  return obj;
}

export function setNestedValue(obj, path, value) {
  if (typeof path === 'string') {
    return setNestedValue(obj, path.split('.'), value);
  }

  const [key, ...remaining] = path;
  if (remaining.length > 0) {
    return {
      ...obj,
      [key]: setNestedValue(obj[key] || {}, remaining, value),
    };
  }

  return {
    ...obj,
    [key]: value,
  };
}
