/**
 * The purpose of this module is to deserialize the application config
 * for a given page. By design, this requires the page to have a script
 * tag of type "application/json" with the id of "js-config".
 *
 * All data contained should be considered static and purely for application
 * hydration.
 */

import freeze from '@mc/fn/freeze';

const destroyed = {};

const destructiveRead = (id) => {
  let data = {};
  const configElement = document.getElementById(id);

  if (!configElement) {
    throw new Error(
      '@mc/page-data: Missing `<script type="application/json" id="' +
        id +
        '">`',
    );
  }

  if (destroyed[id]) {
    throw new Error(
      '@mc/page-data: attempted read of previously destroyed data: ' + id,
    );
  }

  try {
    data = JSON.parse(configElement.textContent);
    configElement.innerText = '';
  } catch (error) {
    if (__DEV__) {
      throw new Error(
        '@mc/page-data: Expected JSON, received: ' + configElement.textContent,
      );
    }
    throw new Error('Cannot parse base page data. App failed to boot');
  }

  destroyed[id] = true;
  return freeze(data);
};

export default destructiveRead;
