import React from 'react';
import PropTypes from 'prop-types';

// Merge Fields
import Text from './Text';
import Email from './Email';
import NumberField from './Number';
import Radio from './Radio';
import Dropdown from './Dropdown';
import Address from './Address';
import Phone from './Phone';
import SmsPhone from './SmsPhone';
import Date from './Date';
import Birthday from './Birthday';
import Zip from './Zip';
import Url from './Url';
import ImageUrl from './ImageUrl';

const MERGE_EMAIL_TYPE = 'email';
const MERGE_TEXT_TYPE = 'text';
const MERGE_NUMBER_TYPE = 'number';
const MERGE_RADIO_TYPE = 'radio';
const MERGE_DROPDOWN_TYPE = 'dropdown';
const MERGE_DATE_TYPE = 'date';
const MERGE_BIRTHDAY_TYPE = 'birthday';
const MERGE_ADDRESS_TYPE = 'address';
const MERGE_ZIP_TYPE = 'zip';
const MERGE_PHONE_TYPE = 'phone';
const MERGE_SMSPHONE_TYPE = 'smsphone';
const MERGE_URL_TYPE = 'url';
const MERGE_IMAGE_URL_TYPE = 'imageurl';

function MergeField({
  field,
  label,
  destination,
  propsFromForm,
  textClassName,
  ...props
}) {
  switch (destination.type) {
    case MERGE_EMAIL_TYPE:
      return (
        <Email
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_TEXT_TYPE:
      return (
        <Text
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_NUMBER_TYPE:
      return (
        <NumberField
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_RADIO_TYPE:
      return (
        <Radio
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_DROPDOWN_TYPE:
      return (
        <Dropdown
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_ADDRESS_TYPE:
      return (
        <Address
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_PHONE_TYPE:
      return (
        <Phone
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_SMSPHONE_TYPE:
      return (
        <SmsPhone
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_DATE_TYPE:
      return (
        <Date
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_BIRTHDAY_TYPE:
      return (
        <Birthday
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_ZIP_TYPE:
      return (
        <Zip
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_URL_TYPE:
      return (
        <Url
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    case MERGE_IMAGE_URL_TYPE:
      return (
        <ImageUrl
          destination={destination}
          field={field}
          label={label}
          propsFromForm={propsFromForm}
          textClassName={textClassName}
          {...props}
        />
      );
    default:
      throw new Error(
        'No component found for merge_field type ' + destination.type,
      );
  }
}
MergeField.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default MergeField;
