import React from 'react';
import PropTypes from 'prop-types';
import fieldStyles from './fields.less';

const ErrorMessage = ({ error }) => {
  return <div className={fieldStyles.errorMessage}>{error}</div>;
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

export default ErrorMessage;
