import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { StackLayout } from '@mc/wink';
import fieldStyles from '../BaseComponent/fields.less';
import FieldContainer from '../BaseComponent/FieldContainer';
import TextField from '../BaseComponent/TextField';
import SelectField from '../BaseComponent/SelectField';
import HelpTextLabel from '../BaseComponent/HelpTextLabel';
import ErrorMessage from '../BaseComponent/ErrorMessage';

const addressComponents = {
  addr1: 'addr1',
  addr2: 'addr2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
};

const getComponentLabel = (component) => {
  switch (component) {
    case addressComponents.addr1:
      return 'Street Address';
    case addressComponents.addr2:
      return 'Address Line 2';
    case addressComponents.city:
      return 'City';
    case addressComponents.state:
      return 'State/Province/Region';
    case addressComponents.zip:
      return 'Postal/Zip Code';
    case addressComponents.country:
      return 'Country';
    default:
      throw new Error('Unknown component name ' + component);
  }
};
const Address = ({
  className,
  destination,
  field,
  label,
  propsFromForm = {},
  textClassName,
  ...props
}) => {
  const initialValues = {};
  destination.components.forEach((componentString) => {
    initialValues[componentString] = propsFromForm.value
      ? propsFromForm.value[componentString]
      : null;
  });

  const onAddressFieldChange = (componentString, componentValue) => {
    const tempObject = {};
    tempObject[componentString] = componentValue;
    if (propsFromForm && propsFromForm.onChange) {
      propsFromForm.onChange({ ...initialValues, ...tempObject });
    }
  };

  const { error } = propsFromForm;

  return (
    <FieldContainer>
      <div
        className={cx(className, {
          [fieldStyles.containerError]: error,
        })}
      >
        <label
          className={fieldStyles.nonDefaultLabel}
          htmlFor={propsFromForm.name}
        >
          {label || propsFromForm.label}
        </label>

        {error && <ErrorMessage error={error} />}

        {field.helpTextEnabled && field.helpText && (
          <HelpTextLabel helpText={field.helpText} className={textClassName} />
        )}

        <StackLayout>
          {destination.components.map((componentString) => {
            if (
              componentString === addressComponents.country &&
              destination.countries
            ) {
              return (
                <SelectField
                  className={className}
                  {...props}
                  options={destination.countries}
                  field={{ helpText: '' }}
                  label={getComponentLabel(componentString)}
                  key={componentString}
                  name={componentString}
                  onChange={(value) => {
                    onAddressFieldChange(componentString, value);
                  }}
                  value={initialValues[componentString] || ''}
                  textClassName={textClassName}
                />
              );
            }
            return (
              <TextField
                className={className}
                {...props}
                field={{ helpText: '' }}
                label={getComponentLabel(componentString)}
                key={componentString}
                name={componentString}
                onChange={(value) => {
                  onAddressFieldChange(componentString, value);
                }}
                value={initialValues[componentString] || ''}
                textClassName={textClassName}
              />
            );
          })}
        </StackLayout>
      </div>
    </FieldContainer>
  );
};

Address.propTypes = {
  className: PropTypes.string,
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Address;
