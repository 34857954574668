import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SubmitButton as WinkSubmitButton, ClusterLayout } from '@mc/wink';

function SubmitButton({ alignment, bgHoverColor, bgColor, label, textColor }) {
  const getButtonAlignmentStyle = (buttonAlignment) => {
    switch (buttonAlignment) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      case 'full':
        return 'space-between';
      default:
        return 'center';
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <ClusterLayout justifyContent={getButtonAlignmentStyle(alignment)}>
      <WinkSubmitButton
        type="primary"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          color: textColor,
          backgroundColor: isHovered ? bgHoverColor : bgColor,
          width: alignment === 'full' ? '100%' : '',
        }}
      >
        {label}
      </WinkSubmitButton>
    </ClusterLayout>
  );
}
SubmitButton.propTypes = {
  alignment: PropTypes.string,
  bgColor: PropTypes.string,
  bgHoverColor: PropTypes.string,
  label: PropTypes.string,
  textColor: PropTypes.string,
};

export default SubmitButton;
