import client from '@mc/networking/http';

/**
 * Describes log classifications as told by Intuit.
 * https://iis-compliance.a.intuit.com/SEC-200-Data-Classification-and-Handling-Policy.pdf
 */

export const CLASSIFICATION = {
  /**
   * Examples include: Cryptographic secrets, private keys, passwords, user credentials,
   * financial institution user credentials, security codes, access codes, PINs, and answers
   * to security questions.
   */
  SECRET: 'secret',

  /**
   * Examples include: Date of birth, government-issued ID numbers (e.g. driver’s license,
   * SSN, TIN, passport), financial account numbers (e.g. bank, credit, debit card numbers),
   * and Protected Health Information (PHI) such as medical record numbers, and financial
   * transaction data containing any other Highly Sensitive elements.
   */
  HIGHLY_SENSITIVE: 'highly_sensitive',

  /**
   * Examples include: Name(First, Middle, or Last Name), phone/fax number, Email,
   * username, street address, marital status, IP addresses, serial numbers, device IDs,
   * Intuit strategic or Merger and Acquisition information, credit score, Intuit vulnerability
   * information(this also correlates to what data the vulnerability might impact), and Intuit
   * non-public source code.
   */
  SENSITIVE: 'sensitive',

  /**
   * Examples include: Internal Intuit memos, organizational information, Intuit Policies and
   * Standards, production logs(not including Sensitive, Highly Sensitive Data, or Secret
   * Data), and non-identifiable product usage data.
   */
  RESTRICTED: 'restricted',

  /**
   * Examples include: Press releases, published financial reports, and approved marketing
   * material.
   */
  PUBLIC: 'public',
};

const CLASSIFICATION_ALLOW_LIST = Object.values(CLASSIFICATION);

/**
 * This function send messages to the `/track/log` endpoint as a means of logging
 * client side messages to ElasticSearch. While it returns a promise, it doesn't expect
 * the result to be awaited on. If there are any errors, it swallows them up in the
 * catch block.
 *
 * @param {Object} logData parameters needed to send messages to ElasticSearch
 *
 * @param {string} logData.channelName name of the channel to send the message to
 * @param {string} logData.message message to send to the channel
 * @param {Object} logData.data data to send to the channel
 * @param {string} logData.classification classification of the data being logged
 *
 * @returns {Promise} response from the server
 */
export default function log({
  channelName,
  message,
  data = {},
  classification,
}) {
  if (typeof channelName !== 'string') {
    throw new Error('channelName must be provided');
  }

  if (typeof message !== 'string') {
    throw new Error('message must be provided');
  }

  if (classification) {
    if (!CLASSIFICATION_ALLOW_LIST.includes(classification)) {
      throw new Error(
        `${classification} is an unexpected logging classification`,
      );
    }

    // Append log classification in the format needed
    data = {
      log_classification: classification,
      ...data,
    };
  }

  const formData = new FormData();
  formData.append('channelName', channelName);
  formData.append('message', message);
  formData.append('data', JSON.stringify(data));

  client.post('/track/log', formData).catch(() => {});
}
