import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './HelpTextLabel.less';

function HelpTextLabel({ className, helpText }) {
  return <span className={cx(className, styles.label)}>{helpText}</span>;
}

HelpTextLabel.propTypes = {
  className: PropTypes.string,
  helpText: PropTypes.string.isRequired,
};

export default HelpTextLabel;
