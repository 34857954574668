/**
 * A recursive wrapper around `Object.freeze`. Calls itself
 * on any object-properties of a given object.
 *
 * @param {Object} obj The object to freeze
 * @return {Object} A deeply-frozen object
 */
function freeze(obj) {
  const properties = Object.getOwnPropertyNames(obj);

  for (let i = 0; i < properties.length; i++) {
    const name = properties[i];
    const value = obj[name];

    if (value && typeof value === 'object') {
      obj[name] = freeze(value);
    }
  }

  return Object.freeze(obj);
}

export default freeze;
