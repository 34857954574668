/**
 * Trigger page reload and set reload param to 1
 * @param {string} paramName - URL parameter name
 * @param {Number} paramValue - URL parameter value
 * @returns {void}
 */
const reloadWithParam = (paramName, paramValue) => {
  const url = new URL(window.location.href);
  url.searchParams.set(paramName, paramValue);
  window.location.href = url.href;
};

/**
 * Get reload parameter name from url
 * @param {string} paramName - URL parameter name
 * @returns {string}
 */
const getReloadParam = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

/**
 * Check if url parameter reload already exists and matches 1
 * @returns {bool}
 */
const reloadInitComplete = () => {
  return getReloadParam('route-reload') === '1';
};

export { reloadWithParam, reloadInitComplete };
