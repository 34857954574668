import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Page, PageContent, WinkStyles, StackLayout } from '@mc/wink';

import ContactForm from '../Main/AudienceForm/ContactForm';
import HeaderImage from '../Main/AudienceForm/ContactForm/HeaderImage';
import styles from './App.less';
import { postForm } from './api';

const FooterBar = () => {
  return (
    <div className={styles.footerBar}>
      <div className={styles.footerBarContent}>
        <a
          className={styles.privacyLink}
          target="_blank"
          href="https://mailchimp.com/contact/abuse/"
          rel="noopener noreferrer"
        >
          Report abuse
        </a>
        <a
          className={styles.createdWithLink}
          target="_blank"
          href="https://mailchimp.com/help/set-up-your-mailchimp-inbox/"
          rel="noopener noreferrer"
        >
          <img
            alt="Mailchimp logo"
            src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
            className={styles.createdWithLogo}
          />
        </a>
      </div>
    </div>
  );
};

const SuccessMessage = ({ success }) => {
  return (
    <div
      className={styles.successMessageContainer}
      // Custom HTML by user on the app. It's stripped server-side
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: success }}
    />
  );
};

SuccessMessage.propTypes = {
  success: PropTypes.string.isRequired,
};

const App = ({ userId, formId, contactForm, destinations }) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleForm = (formData) => {
    if (submitting) {
      return {};
    }

    setSubmitting(true);

    return postForm({ userId, formId, formData })
      .then((data) => {
        setSubmitting(false);

        if (data.success) {
          setSuccess(true);
          return {};
        }

        const formErrors = {};
        Object.keys(data.errors).forEach((fieldId) => {
          if (fieldId === 'globalError') {
            formErrors[fieldId] = data.errors[fieldId];
          } else {
            formErrors[`fields.${fieldId}`] = data.errors[fieldId];
          }
        });

        return {
          errors: formErrors,
        };
      })
      .catch((error) => {
        return {
          errors: error.cause,
        };
      });
  };

  return (
    <WinkStyles>
      <div
        className={styles.rootContainer}
        style={{ backgroundColor: contactForm.options.pageBgColor }}
      >
        <Page>
          <PageContent>
            <div className={styles.pageContainer}>
              <div className={styles.innerContainer}>
                {success ? (
                  <SuccessMessage
                    success={contactForm.options.successMessage}
                  />
                ) : (
                  <StackLayout>
                    <HeaderImage
                      imageSize={contactForm.options.headerImageSize}
                      imageUrl={contactForm.options.headerImageUrl}
                    />
                    <ContactForm
                      fields={contactForm.fields}
                      options={contactForm.options}
                      onSubmit={handleForm}
                      description={contactForm.options.description}
                      submitButtonLabel={contactForm.options.submitButtonLabel}
                      submitButtonBgColor={
                        contactForm.options.submitButtonBgColor
                      }
                      submitButtonBgHoverColor={
                        contactForm.options.submitButtonBgHoverColor
                      }
                      submitButtonTextColor={
                        contactForm.options.submitButtonTextColor
                      }
                      submitButtonAlignment={
                        contactForm.options.submitButtonAlignment
                      }
                      subscribeCheckboxLabel={
                        contactForm.options.subscribeCheckboxLabel
                      }
                      destinations={destinations}
                    />
                  </StackLayout>
                )}
              </div>
            </div>
          </PageContent>
        </Page>
        <div className={styles.footerContainer}>
          <FooterBar />
        </div>
      </div>
    </WinkStyles>
  );
};

App.propTypes = {
  contactForm: PropTypes.object.isRequired,
  destinations: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default App;
