import React from 'react';
import PropTypes from 'prop-types';
import FieldContainer from '../BaseComponent/FieldContainer';
import TextField from '../BaseComponent/TextField';

const Url = ({ field, label, propsFromForm, textClassName, ...props }) => {
  return (
    <FieldContainer>
      <TextField
        field={field}
        label={label}
        propsFromForm={propsFromForm}
        textClassName={textClassName}
        {...props}
      />
    </FieldContainer>
  );
};

Url.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Url;
