import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FieldContainer from '../BaseComponent/FieldContainer';
import fieldStyles from '../BaseComponent/fields.less';
import DateField from '../BaseComponent/DateField';

const Date = ({
  destination,
  field,
  label,
  propsFromForm = {},
  textClassName,
}) => {
  const initialValue = {};
  if (propsFromForm.value) {
    const [year, month, day] = propsFromForm.value.split('-');
    initialValue.YYYY = year;
    initialValue.MM = month;
    initialValue.DD = day;
  }

  const [dateFieldValue, setDateFieldValue] = useState(initialValue);

  const handleChangeFromForm = (value) => {
    if (propsFromForm && propsFromForm.onChange) {
      propsFromForm.onChange(value);
    }
  };

  const handleChange = (updatedDateFieldValue) => {
    const { YYYY: year, MM: month, DD: day } = updatedDateFieldValue;
    const value = year && month && day ? `${year}-${month}-${day}` : '';
    handleChangeFromForm(value);
    setDateFieldValue(updatedDateFieldValue);
  };

  const { error } = propsFromForm;

  return (
    <FieldContainer>
      <div
        className={cx({
          [fieldStyles.containerError]: error,
        })}
      >
        <DateField
          label={label || propsFromForm.label}
          field={field}
          value={dateFieldValue}
          error={error}
          dateFormat={destination.dateformat}
          onChange={handleChange}
          textClassName={textClassName}
        />
      </div>
    </FieldContainer>
  );
};

Date.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Date;
