import readTranslations from '@mc/translation-data';
import client from '@mc/networking/http';
import {
  SUPPORTED_LOCALES,
  LANGUAGE_SELECTOR_LOCALE_GROUPS,
  LOCALE_TITLES,
  SUPPORTED_LANGUAGES,
} from './constants';

/**
 * In order to make sure that we return a supported language, we need to check
 * the language given and return with a valid language.
 * @param {String} locale
 *
 * @returns {String}
 */
export function getLocaleSelectorTitle(locale) {
  const foundLocaleTitle = Object.keys(
    LANGUAGE_SELECTOR_LOCALE_GROUPS,
  ).find((localeTitle) =>
    LANGUAGE_SELECTOR_LOCALE_GROUPS[localeTitle].includes(locale),
  );

  return foundLocaleTitle || LOCALE_TITLES.ENGLISH;
}

/**
 * In order to make sure that we return a supported language, we need to check
 * the language given and return with a valid language.
 * @param {String} locale
 *
 * @returns {String | undefined}
 */
export function matchToSupportedLocale(locale) {
  return Object.values(SUPPORTED_LOCALES).find(
    (localeItem) =>
      localeItem.toLocaleLowerCase() === locale.toLocaleLowerCase(),
  );
}

export function matchToSupportedLanguage(language) {
  return Object.values(SUPPORTED_LANGUAGES).find(
    (languageItem) =>
      languageItem.toLocaleLowerCase() === language.toLocaleLowerCase(),
  );
}

/**
 * In order to make sure that we return a supported language, we need to check
 * the language given and return with a valid language.
 * @param {String} locale
 *
 * @returns {String}
 */
export function returnSupportedLocale(locale = SUPPORTED_LOCALES.DEFAULT) {
  if (typeof locale !== 'string') {
    // TODO: Throw an error if `language` is not a string and return the default LOCALIZATIONS_MAPPING
    return SUPPORTED_LOCALES.DEFAULT;
  }

  // Convert both the locales list and the passed in locale to lowercase for comparision
  const localeMatch = matchToSupportedLocale(locale);

  const languageMatch = matchToSupportedLanguage(
    extractLanguageFromLocale(locale),
  );

  // If we found a match, return that, otherwise return the default
  return localeMatch || languageMatch || SUPPORTED_LOCALES.DEFAULT;
}

/**
 * In order to make sure that we return a supported language, we need to check
 * the language given and return with a valid language.
 * @param {String} inputString
 *
 * @returns {?String}
 */
export function extractLocaleIfExists(inputString) {
  if (typeof inputString !== 'string') {
    return null;
  }

  const localeRegex = /(?:(?:^[A-Za-z]{2,3})(?:-[A-Za-z]{2,4})*)(?![-A-Za-z])/;
  const possibleMatch = inputString.match(localeRegex);
  return possibleMatch ? possibleMatch[0] : null;
}

/**
 * Extract the language from a given locale string
 * @param {String} localeString
 *
 * @returns {?String}
 */
export function extractLanguageFromLocale(localeString) {
  if (typeof localeString !== 'string') {
    return null;
  }

  return localeString.split('-')[0];
}

/**
 *
 * @returns {Object}
 */
export function getTranslationsBE() {
  const translationsFile = readTranslations();
  if (!translationsFile || Object.keys(translationsFile).length < 3) {
    // pass through undefined, throw error when applicable
    if (translationsFile && translationsFile.status === 'error') {
      throw new Error(
        `Language resource file failed to load in React controller - ${translationsFile.error}`,
      );
    }
  } else {
    return translationsFile;
  }
}

/**
 * @param {String} locale
 * @returns {Object}
 */
export const getMergedReactTranslations = (locale) => {
  return client
    .get(`/i18n/react/get-translations?locale=${locale}`)
    .then(async (response) => {
      try {
        const responseBody = await response.json();
        if (response.ok && responseBody) {
          return responseBody;
        }
        if ('error' in responseBody) {
          throw new Error(
            `File failed to load in React controller - ${responseBody.error}`,
          );
        }
      } catch (e) {
        if (response.status === 403) {
          return {};
        }
        throw new Error(
          `Merged language resource file failed to load - ${e}. Response: ${response}`,
        );
      }
    });
};

/**
 * Prioritize getting local in order of:
 *  1. Override Input
 *  2. Parsed AppConfig (when flag is set)
 *  4. Global Navigator Object
 *  5. Supported Default Locale
 *
 * This logic should mirror what is happening within `app/lib/Support/L10n/Locale.php`
 * @param {String} localeOverride
 * @returns {String}
 */
export function getDisplayLocale(localeOverride) {
  let prioritizedLocale = null;
  let translationsFile = null;

  if (localeOverride) {
    prioritizedLocale = localeOverride;
  }

  try {
    translationsFile = getTranslationsBE();
  } catch {
    translationsFile = { i18n_language_preference: SUPPORTED_LOCALES.DEFAULT };
  } finally {
    if (translationsFile?.i18n_language_preference) {
      prioritizedLocale = translationsFile?.i18n_language_preference;
    }
  }

  return returnSupportedLocale(prioritizedLocale);
}

/**
 * @param {String} locale
 * @returns {Promise<Response>}
 */
export function setDisplayLocale(locale) {
  return client.post(
    '/i18n/locales/update-post',
    JSON.stringify({ locale: locale }),
  );
}

/**
 * @param {Object} translations
 * @returns {boolean}
 */
export function getFullTranslationsStatus(translations) {
  try {
    if (translations?.i18n_full_translations_loaded === 'true') {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

/**
 * The standard import for the flags module is forbidden within this file
 * Will approach flag validation in a similar way as
 * https://git.rsglab.com/product/mailchimp/blob/main/web/js/mojo/context.js
 * Ensuring the data exists prior to validation
 * @param {String} flagKey
 * @returns {boolean}
 */
export function validateJsFlag(flagKey) {
  try {
    if (document && document.getElementById('js-legacy-flag-data')) {
      const flagArr = JSON.parse(
        document.getElementById('js-legacy-flag-data').textContent,
      );
      return !!flagArr?.includes && flagArr.includes(flagKey);
    }

    return false;
  } catch {
    return false;
  }
}
