import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from '@mc/wink';
import HelpTextLabel from './HelpTextLabel';
import styles from './fields.less';

const SelectField = ({
  field,
  label,
  options,
  propsFromForm = {},
  textClassName,
  ...props
}) => {
  return (
    <div>
      <label
        aria-hidden="true"
        className={styles.nonDefaultLabel}
        htmlFor={propsFromForm.name}
      >
        {label || propsFromForm.label}
      </label>

      <div className={styles.selectWrapper}>
        <Select
          hideLabel={true}
          label={label}
          {...propsFromForm}
          {...props}
          id={propsFromForm.name}
        >
          {options.map((option) => {
            return (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            );
          })}
        </Select>
      </div>
      {field.helpTextEnabled && field.helpText && (
        <HelpTextLabel helpText={field.helpText} className={textClassName} />
      )}
    </div>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default SelectField;
