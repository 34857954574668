import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../BaseComponent/TextField';
import FieldContainer from '../BaseComponent/FieldContainer';

const Phone = ({ field, label, propsFromForm, textClassName, ...props }) => {
  return (
    <FieldContainer>
      <TextField
        field={field}
        label={label}
        propsFromForm={propsFromForm}
        textClassName={textClassName}
        type="tel"
        {...props}
      />
    </FieldContainer>
  );
};

Phone.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Phone;
