import { useTranslateMessage } from '@mc/internationalization/translationUtils';

export const usePopupEditorText = () => {
  return {
    navTitle: useTranslateMessage({
      id: 'flp.popup_editor.nav_title',
      defaultMessage: 'Pop-up Form Builder',
    }),
    previewCopy: useTranslateMessage({
      id: 'flp.popup_editor.preview',
      defaultMessage: 'Preview',
    }),
    saveChangesCopy: useTranslateMessage({
      id: 'flp.popup_editor.save_changes',
      defaultMessage: 'Save changes',
    }),
    saveAndPublishCopy: useTranslateMessage({
      id: 'flp.popup_editor.save_and_publish',
      defaultMessage: 'Save & publish',
    }),
    allChangesSavedCopy: useTranslateMessage({
      id: 'flp.popup_editor.all_changes_saved',
      defaultMessage: 'All changes saved...',
    }),
    generalSettingsLabel: useTranslateMessage({
      id: 'flp.popup_editor.general_settings_label',
      defaultMessage: 'General settings',
    }),
    generalLabel: useTranslateMessage({
      id: 'flp.popup_editor.general_label',
      defaultMessage: 'General',
    }),
    bannerSettingsLabel: useTranslateMessage({
      id: 'flp.popup_editor.banner_settings_label',
      defaultMessage: 'Banner settings',
    }),
    smsSignupLabel: useTranslateMessage({
      id: 'flp.popup_editor.sms_signup_label',
      defaultMessage: 'SMS signup',
    }),
    formSettingsLabel: useTranslateMessage({
      id: 'flp.popup_editor.form_settings_label',
      defaultMessage: 'Form settings',
    }),
    successSettingsLabel: useTranslateMessage({
      id: 'flp.popup_editor.success_settings_label',
      defaultMessage: 'Success settings',
    }),
    labelAfter5Seconds: useTranslateMessage({
      id: 'flp.popup_editor.label_after_5_seconds',
      defaultMessage: 'After 5 seconds',
    }),
    labelAfter20Seconds: useTranslateMessage({
      id: 'flp.popup_editor.label_after_20_seconds',
      defaultMessage: 'After 20 seconds',
    }),
    labelImmediately: useTranslateMessage({
      id: 'flp.popup_editor.label_immediately',
      defaultMessage: 'Immediately',
    }),
    labelScrollToMiddleOfPage: useTranslateMessage({
      id: 'flp.popup_editor.label_scroll_middle_page',
      defaultMessage: 'Scroll to middle of page',
    }),
    labelScrollToEndOfPage: useTranslateMessage({
      id: 'flp.popup_editor.label_scroll_end_page',
      defaultMessage: 'Scroll to end of page',
    }),
    labelOnExit: useTranslateMessage({
      id: 'flp.popup_editor.label_on_exit',
      defaultMessage: 'On exit',
    }),
    labelDontShowAgainFor: useTranslateMessage({
      id: 'flp.popup_editor.label_dont_show_again_for',
      defaultMessage: "Don't show again for",
    }),
    labelDays: useTranslateMessage({
      id: 'flp.popup_editor.label_days',
      defaultMessage: 'days',
    }),
    closeSuccessMessageAfterLabel: useTranslateMessage({
      id: 'flp.popup_editor.close_success_message_after',
      defaultMessage: 'Close success message after',
    }),
    labelSeconds: useTranslateMessage({
      id: 'flp.popup_editor.label_seconds',
      defaultMessage: 'seconds',
    }),
    badgeAltText: useTranslateMessage({
      id: 'intuit_mailchimp_logo',
      defaultMessage: 'Intuit Mailchimp logo',
    }),
    customBannerDescriptionLabel: useTranslateMessage({
      id: 'flp.popup_editor.custom_banner_description',
      defaultMessage: 'Add a custom banner description',
    }),
    customConfirmationMessageLabel: useTranslateMessage({
      id: 'flp.popup_editor.custom_confirmation_message',
      defaultMessage: 'Add a custom confirmation message',
    }),
    addHeadlineOrDescription: useTranslateMessage({
      id: 'flp.popup_editor.add_headline_or_description',
      defaultMessage: 'Add a headline or description',
    }),
    addDisclaimerOrNote: useTranslateMessage({
      id: 'flp.popup_editor.add_disclaimer_or_note',
      defaultMessage: 'Add a disclaimer or note',
    }),
    enterYourEmail: useTranslateMessage({
      id: 'flp.popup_editor.enter_your_email',
      defaultMessage: 'Enter your email',
    }),
    displayLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_display',
      defaultMessage: 'Display',
    }),
    enableMobileBannerLabel: useTranslateMessage({
      id: 'flp.popup_editor.enable_mobile_banner',
      defaultMessage: 'Enable mobile banner',
    }),
    overlayOpacityLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_overlay_opacity',
      defaultMessage: 'Overlay opacity',
    }),
    showMailchimpBadgeLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_show_mc_badge',
      defaultMessage: 'Show Mailchimp badge',
    }),
    positionLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_position',
      defaultMessage: 'Position',
    }),
    slideLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_slide',
      defaultMessage: 'Slide',
    }),
    modalLabel: useTranslateMessage({
      id: 'flp.popup_editor.label_modal',
      defaultMessage: 'Modal',
    }),
    gdprSlideFieldWarning: useTranslateMessage({
      id: 'flp.popup_editor.gdpr_slide_field_warning',
      defaultMessage: 'GDPR Fields are not supported on Slide position.',
    }),
    supportsGdprLabel: useTranslateMessage({
      id: 'flp.popup_editor.supports_gdpr',
      defaultMessage: 'Supports GDPR',
    }),
    doesNotIncludeSmsLabel: useTranslateMessage({
      id: 'flp.popup_editor.does_not_include_sms',
      defaultMessage: 'Does not include SMS',
    }),
    mobileBannerHelperText: useTranslateMessage({
      id: 'flp.popup_editor.mobile_banner_helper_text',
      defaultMessage:
        'The mobile banner will appear on your mobile site to give your customers the choice to open your popup.',
    }),
    saveModalTitle: useTranslateMessage({
      id: 'flp.popup_editor.save_modal_title',
      defaultMessage: 'Modal that updates you about the status of your changes',
    }),
    saveModalHeaderLabel: useTranslateMessage({
      id: 'flp.popup_editor.save_modal_header',
      defaultMessage: 'Save changes',
    }),
    changesSavedNotVisibleHeading: useTranslateMessage({
      id: 'flp.popup_editor.changes_saved_not_visible_heading',
      defaultMessage:
        'You can save your changes, but they’re not visible on your site',
    }),
    changesSavedNotVisibleBody: useTranslateMessage({
      id: 'flp.popup_editor.changes_saved_not_visible_body',
      defaultMessage:
        'Your pop-up form is not enabled on your website. We’ll save these changes, but this pop-up form will not display on your website until you enable it.',
    }),
    aboutToPublishHeading: useTranslateMessage({
      id: 'flp.popup_editor.about_to_publish_heading',
      defaultMessage: 'You’re about to publish changes to your popup form.',
    }),
    aboutToPublishBody: useTranslateMessage({
      id: 'flp.popup_editor.about_to_publish_body',
      defaultMessage: 'This will update your popup form on these sites:',
    }),
    savedButNoConnectedSitesHeading: useTranslateMessage({
      id: 'flp.popup_editor.saved_but_no_connected_sites_heading',
      defaultMessage:
        'Your changes will be saved, but you don’t have a site connected.',
    }),
    savedButNoConnectedSitesBody: useTranslateMessage({
      id: 'flp.popup_editor.saved_but_no_connected_sites_body',
      defaultMessage: "When you're ready, connect a site.",
    }),
    cancelCopy: useTranslateMessage({
      id: 'flp.popup_editor.cancel_copy',
      defaultMessage: 'Cancel',
    }),
    savingCopy: useTranslateMessage({
      id: 'flp.popup_editor.saving_copy',
      defaultMessage: 'Saving...',
    }),
    upgradeCtaCopy: useTranslateMessage({
      id: 'flp.popup_editor.upgrade_cta_copy',
      defaultMessage: 'to remove branding',
    }),
    backToBuilderLabel: useTranslateMessage({
      id: 'flp.popup_editor.back_to_builder',
      defaultMessage: 'Back to builder',
    }),
    continueLabel: useTranslateMessage({
      id: 'flp.popup_editor.continue',
      defaultMessage: 'Continue',
    }),
    saveModalContentLabel: useTranslateMessage({
      id: 'flp.popup_editor.save_modal_content',
      defaultMessage:
        'To save and publish changes on this pop-up form, enable the pop-up form to display on your connected site(s). Disabling will save your changes without publishing.',
    }),
    sms_flag_alt_text: useTranslateMessage({
      id: 'flp.popup_editor.sms_flag_alt_text',
      defaultMessage: 'Country Code',
    }),
  };
};
