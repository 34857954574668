import React from 'react';
import PropTypes from 'prop-types';
import { ClusterLayout } from '@mc/wink';
import TextField from '../BaseComponent/TextField';
import FieldContainer from '../BaseComponent/FieldContainer';
import { usePopupEditorText } from '../../../../SignupForms/hooks/usePopupEditorText';

const SMSPhoneSection = ({ legalText, smsProgramData, required }) => {
  const requiredLegal = smsProgramData
    ? smsProgramData[0].requiredTemplate
    : legalText.required;
  const optionalLegal = smsProgramData
    ? smsProgramData[0].optionalTemplate
    : legalText.optional;
  const divRegex = /<\/?[div][^>]*>/gi;
  const fullAnchorRegex = /<a.*?<\/a>/g;
  const anchorRegex = /<a href="(.*?)" target="(.*?)">(.*?)<\/a>/;
  const requiredLegalText = requiredLegal
    .replace(divRegex, '')
    .replace(fullAnchorRegex, '')
    .slice(0, -1);
  const optionalLegalText = optionalLegal
    .replace(divRegex, '')
    .replace(fullAnchorRegex, '')
    .slice(0, -1);
  const anchorMatches = requiredLegal.match(anchorRegex); //the anchor stays the same for required and optional
  if (!anchorMatches) {
    return '';
  }
  return (
    <div
      className="mc-sms-phone-group"
      style={{ display: 'flex', gap: '15px', alignItems: 'center' }}
    >
      <label style={{ alignItems: 'center' }}>
        {required && (
          <input
            type="checkbox"
            name="mc-SMSPHONE-ack"
            id="mc-SMSPHONE-ack"
            value="true"
            style={{ marginRight: '5px', verticalAlign: 'text-bottom' }}
          />
        )}
        {required ? requiredLegalText : optionalLegalText}
        <span>
          <a href={anchorMatches[1]} target={anchorMatches[2]}>
            {anchorMatches[3]}
          </a>
        </span>
        .
      </label>
    </div>
  );
};

SMSPhoneSection.propTypes = {
  legalText: PropTypes.any.isRequired,
  required: PropTypes.bool,
  smsProgramData: PropTypes.array,
};

const returnCountryCode = (smsProgramData, altText) => {
  return (
    <ClusterLayout>
      <img
        src={smsProgramData.imgSrc}
        alt={altText}
        width="28"
        height="28"
      ></img>
    </ClusterLayout>
  );
};

const SmsPhone = ({
  field,
  label,
  propsFromForm,
  textClassName,
  destination,
  ...props
}) => {
  const { sms_flag_alt_text } = usePopupEditorText();
  return (
    <FieldContainer>
      <TextField
        field={field}
        label={label}
        propsFromForm={propsFromForm}
        textClassName={textClassName}
        type="tel"
        prefixText={
          destination.smsProgramData
            ? returnCountryCode(
                destination.smsProgramData[0],
                sms_flag_alt_text,
              )
            : ''
        }
        {...props}
      />
      <SMSPhoneSection
        legalText={destination.legal}
        smsProgramData={destination.smsProgramData}
        required={field.isRequired}
      />
    </FieldContainer>
  );
};

SmsPhone.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default SmsPhone;
