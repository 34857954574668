import React, { useContext } from 'react';
import cx from 'classnames';
import { NotAllowedIcon } from '@mc/wink-icons';
import { FormContext } from '@mc/wink';

import stylesheet from './FormFeedback.less';

const FormFeedback = () => {
  const { isValid, hasSubmitted, errors } = useContext(FormContext);
  const isActive = hasSubmitted && !isValid;

  const title =
    errors.globalError || 'There were some errors with your submission.';

  return (
    <div
      role="alert"
      className={cx(stylesheet.formFeedback, {
        'wink-visually-hidden': !isActive,
      })}
    >
      {isActive && (
        <React.Fragment>
          <div className={stylesheet.feedbackIcon}>
            <NotAllowedIcon />
          </div>
          <div>
            <strong>{title}</strong>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default FormFeedback;
