import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Field/BaseComponent/TextField';

function Subject({ field, label, propsFromForm, textClassName, ...props }) {
  return (
    <TextField
      field={field}
      label={label}
      propsFromForm={propsFromForm}
      textClassName={textClassName}
      {...props}
    />
  );
}

Subject.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Subject;
