import React from 'react';
import PropTypes from 'prop-types';

import Bugsnag from '@bugsnag/js';
import { Form, FormField, Checkbox, StackLayout } from '@mc/wink';
import { getDestinationForField } from '../FormFieldManager/destinations';
import TextField from '../Field/BaseComponent/TextField';
import FieldContainer from '../Field/BaseComponent/FieldContainer';
import fieldLabel from '../Field/BaseComponent/fieldLabel';
import Field from './Field';
import FormFeedback from './FormFeedback';
import SubmitButton from './SubmitButton';

function ContactForm({
  description,
  destinations,
  fields,
  onSubmit,
  submitButtonAlignment,
  submitButtonBgHoverColor,
  submitButtonBgColor,
  submitButtonLabel,
  submitButtonTextColor,
  subscribeCheckboxLabel,
}) {
  const initialFormValues = {
    fields: fields.reduce((map, field) => {
      map[field.id] = '';
      return map;
    }, {}),
    subscribe: false,
    'mc-SMSPHONE-ack': false,
  };
  let smsMergeFieldId = null;
  let legalText = {};
  let requiredLegalText = '';
  let optionalLegalText = '';
  let anchorMatches = [];
  destinations.merge_field.some((field) => {
    if (field.type === 'smsphone') {
      smsMergeFieldId = field.id;
      legalText = field.legal;
      const divRegex = /<\/?[div][^>]*>/gi;
      const fullAnchorRegex = /<a.*?<\/a>/g;
      const anchorRegex = /<a href="(.*?)" target="(.*?)">(.*?)<\/a>/;
      requiredLegalText = legalText.required
        .replace(divRegex, '')
        .replace(fullAnchorRegex, '')
        .slice(0, -1);
      optionalLegalText = legalText.optional
        .replace(divRegex, '')
        .replace(fullAnchorRegex, '')
        .slice(0, -1);
      anchorMatches = legalText.required.match(anchorRegex); //the anchor stays the same for required and optional
      if (!anchorMatches) {
        return '';
      }
      return true;
    }
    return false;
  });

  return (
    <Form onSubmit={onSubmit} initialValues={initialFormValues}>
      {description && (
        // This is user generated, but we're stripping it on save
        // eslint-disable-next-line react/no-danger
        <StackLayout dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {fields.map((field) => {
        // If the field or destination is missing, fail to render the field
        // but let the page render still
        try {
          if (!field) {
            throw new Error(`Field ${field} is missing`);
          }

          const destination = getDestinationForField(field, destinations);

          if (field.destination.id === smsMergeFieldId) {
            return (
              <FieldContainer key={field.id}>
                <FormField
                  name={`fields.${field.id}`}
                  component={(propsFromForm, ...props) => {
                    return (
                      <TextField
                        field={field}
                        label={fieldLabel(field, destination)}
                        propsFromForm={propsFromForm}
                        textClassName="contact-form-field"
                        type="tel"
                        {...props}
                      />
                    );
                  }}
                  label={fieldLabel(field, destination)}
                />
                <div
                  className="mc-sms-phone-group"
                  style={{
                    display: 'flex',
                    gap: '15px',
                    marginTop: '12px',
                  }}
                >
                  {field.isRequired && (
                    <FormField name="mc-SMSPHONE-ack" component={Checkbox} />
                  )}
                  <div>
                    {field.isRequired ? requiredLegalText : optionalLegalText}
                    &nbsp;
                    <a href={anchorMatches[1]} target={anchorMatches[2]}>
                      {anchorMatches[3]}
                    </a>
                    .
                  </div>
                </div>
              </FieldContainer>
            );
          }

          return (
            <Field field={field} key={field.id} destination={destination} />
          );
        } catch (error) {
          Bugsnag.notify(error);
          return null;
        }
      })}
      <FormField
        label={subscribeCheckboxLabel}
        name="subscribe"
        component={Checkbox}
      />
      <FormFeedback />
      <SubmitButton
        alignment={submitButtonAlignment}
        bgHoverColor={submitButtonBgHoverColor}
        bgColor={submitButtonBgColor}
        label={submitButtonLabel}
        textColor={submitButtonTextColor}
      />
    </Form>
  );
}

ContactForm.propTypes = {
  description: PropTypes.string,
  destinations: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonAlignment: PropTypes.string,
  submitButtonBgColor: PropTypes.string,
  submitButtonBgHoverColor: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  submitButtonTextColor: PropTypes.string,
  subscribeCheckboxLabel: PropTypes.string,
};

export default ContactForm;
