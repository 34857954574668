import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from '@mc/wink';
import styles from './fields.less';
import HelpTextLabel from './HelpTextLabel';
import ErrorMessage from './ErrorMessage';

const RadioGroupField = ({
  field,
  label,
  options,
  propsFromForm = {},
  textClassName,
  ...props
}) => {
  const { error } = propsFromForm;

  return (
    <div>
      <label
        aria-hidden="true"
        className={styles.nonDefaultLabel}
        htmlFor={propsFromForm.name}
      >
        {label || propsFromForm.label}
      </label>

      {error && <ErrorMessage error={error} />}

      {field.helpTextEnabled && field.helpText && (
        <HelpTextLabel helpText={field.helpText} className={textClassName} />
      )}

      <RadioGroup
        {...propsFromForm}
        {...props}
        label={<div className="wink-visually-hidden">{label}</div>}
        id={propsFromForm.name}
      >
        {options.map((option) => {
          return <Radio {...option} key={option.id} />;
        })}
      </RadioGroup>
    </div>
  );
};

RadioGroupField.propTypes = {
  field: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default RadioGroupField;
