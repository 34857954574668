import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FieldContainer from '../BaseComponent/FieldContainer';
import fieldStyles from '../BaseComponent/fields.less';
import DateField from '../BaseComponent/DateField';

const Birthday = ({
  destination,
  field,
  label,
  propsFromForm = {},
  textClassName,
}) => {
  const initialValue = propsFromForm.value
    ? {
        MM: propsFromForm.value.month,
        DD: propsFromForm.value.day,
      }
    : {};

  const [dateFieldValue, setDateFieldValue] = useState(initialValue);

  const handleChangeFromForm = (value) => {
    if (propsFromForm && propsFromForm.onChange) {
      propsFromForm.onChange(value);
    }
  };

  const handleChange = (updatedDateFieldValue) => {
    const { MM: month, DD: day } = updatedDateFieldValue;
    const value = month && day ? { month, day } : {};
    handleChangeFromForm(value);
    setDateFieldValue(updatedDateFieldValue);
  };

  const { error } = propsFromForm;

  return (
    <FieldContainer>
      <div
        className={cx({
          [fieldStyles.containerError]: error,
        })}
      >
        <DateField
          label={label || propsFromForm.label}
          field={field}
          value={dateFieldValue}
          error={error}
          onChange={handleChange}
          dateFormat={destination.dateformat}
          textClassName={textClassName}
        />
      </div>
    </FieldContainer>
  );
};

Birthday.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Birthday;
