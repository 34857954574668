import React from 'react';
import PropTypes from 'prop-types';
import TextareaField from '../../Field/BaseComponent/TextareaField';

function Message({
  field,
  destination,
  label,
  propsFromForm,
  textClassName,
  ...props
}) {
  const { maxLength } = destination;

  return (
    <TextareaField
      field={field}
      label={label}
      propsFromForm={propsFromForm}
      textClassName={textClassName}
      suggestedMaxLength={maxLength}
      rows={8}
      {...props}
    />
  );
}

Message.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Message;
