import { useEffect } from 'react';

/**
 * Fully accessible hook for handling clicks outside of a component. Handles
 * keyboard and touch events via `focusout` and `touchstart`.
 *
 * @param {*} node - A React ref. Clicking outside the ref triggers the handler.
 * @param {func} handler - The function to execute when a user clicks outside.
 */
function useOutsideClick(node, handler) {
  if (!node || !node.hasOwnProperty('current')) {
    throw new Error('useOutsideClick expects its first argument to be a ref');
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!node || !node.current) {
        return;
      }

      /** Avoid treating clicks into a nested Portal as outside clicks */
      const parentPortal = node.current.closest('[data-portal-stack]');
      const closestPortal = event.target.closest('[data-portal-stack]');

      if (parentPortal && closestPortal && parentPortal !== closestPortal) {
        const parentStack = parentPortal.dataset.portalStack;
        const closestStack = closestPortal.dataset.portalStack;

        if (closestStack.includes(parentStack)) {
          return;
        }
      }

      if (!node.current.contains(event.target)) {
        handler(event);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [node, handler]);
}

export default useOutsideClick;
