const DestinationTypes = {
  MERGE_FIELD: 'merge_field',
  GROUP: 'group',
  CONTACT_FORM_FIELD: 'contact_form_field',
  EMAIL: 'email', // Should be able to remove this after the ELC release - check pop-up form
};

const DestinationChannelIds = {
  EMAIL: 0,
};

const destinationsEqual = (x, y) => {
  if (x.type !== y.type) {
    return false;
  }

  switch (x.type) {
    case DestinationTypes.MERGE_FIELD:
    case DestinationTypes.GROUP:
    case DestinationTypes.CONTACT_FORM_FIELD:
      return x.id === y.id;
    default:
      return true;
  }
};

function byId(id) {
  return (item) => item.id === id;
}

// For a given field, find it's corresponding destination
const getDestinationForField = (field, destinations) => {
  const destinationType = field.destination.type;
  const destinationId = field.destination.id;
  const destination = destinations[destinationType]?.find(byId(destinationId));

  switch (destinationType) {
    case DestinationTypes.EMAIL: // Should be able to remove this after the ELC release - check pop-up form
      return {
        id: DestinationChannelIds.EMAIL,
        name: 'Email',
        type: DestinationTypes.EMAIL,
      };
    case DestinationTypes.MERGE_FIELD:
    case DestinationTypes.GROUP:
    case DestinationTypes.CONTACT_FORM_FIELD:
      if (destination) {
        return destination;
      }
      throw new Error('Unknown destination with ID of: ' + destinationId);

    default:
      throw new Error('Unknown Form field type found: ' + destinationType);
  }
};

// Create a key of available inputs to add to the form, omitting
// any inputs that are already used
const calculateAvailableDestinations = (inputs, destinations) => {
  let isContactForm = false;
  const activeInputs = new Set();

  inputs.forEach((input) => {
    const { destination } = input;
    activeInputs.add(destination.id);
  });

  const allDestinations = [];
  Object.keys(destinations).forEach((key) => {
    if (
      key === DestinationTypes.MERGE_FIELD ||
      key === DestinationTypes.GROUP ||
      key === DestinationTypes.CONTACT_FORM_FIELD
    ) {
      destinations[key].forEach((destination) => {
        allDestinations.push({
          type: key,
          id: destination.id,
          name: destination.name,
        });
      });
    }

    if (key === DestinationTypes.CONTACT_FORM_FIELD) {
      isContactForm = true;
    }
  });

  const transformedDestinations = [];

  if (isContactForm) {
    transformedDestinations.push({
      type: DestinationTypes.EMAIL,
      id: DestinationChannelIds.EMAIL,
      name: 'Email',
      value: {
        type: DestinationTypes.EMAIL,
        id: DestinationChannelIds.EMAIL,
      },
      available: !activeInputs.has(DestinationChannelIds.EMAIL),
    });
  }

  allDestinations.forEach((destination) => {
    transformedDestinations.push({
      type: destination.type,
      id: destination.id,
      name: destination.name,
      value: {
        type: destination.type,
        id: destination.id,
      },
      available: !activeInputs.has(destination.id),
    });
  });
  return transformedDestinations;
};

export {
  DestinationTypes,
  DestinationChannelIds,
  calculateAvailableDestinations,
  destinationsEqual,
  getDestinationForField,
};
