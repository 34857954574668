import { useState } from 'react';

let _id = 0;

const incrementId = () => {
  _id += 1;
  return 'mc:' + _id;
};

/**
 * Creates a guaranteed-unique ID for DOM elements.
 *
 * The id will be refreshed when the calling component is re-rendered.
 *
 * @returns {(string)} a unique string ID.
 */
const useId = () => {
  const [id] = useState(incrementId);
  return id;
};

export default useId;
