/**
 * Returns a function that calls the passed event handlers in a chain. The
 * returned function takes one argument -- the event itself. Calling
 * `event.preventDefault()` will stop the call chain.
 *
 * @param  {...function} handlers Event handlers to be called together
 */
function chainHandlers(...handlers) {
  return (event) => {
    for (const handler of handlers) {
      if (event.defaultPrevented) {
        break;
      } else if (handler) {
        handler(event);
      }
    }
  };
}

export default chainHandlers;
