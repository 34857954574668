import React from 'react';
import PropTypes from 'prop-types';
import FieldContainer from '../BaseComponent/FieldContainer';
import SelectField from '../BaseComponent/SelectField';

const Dropdown = ({
  field,
  destination,
  label,
  propsFromForm,
  textClassName,
  ...props
}) => {
  const options = [
    {
      id: '',
      name: 'Select...',
    },
  ];
  destination.choices.forEach((choice) => {
    options.push({
      id: choice,
      name: choice,
    });
  });

  return (
    <FieldContainer>
      <SelectField
        field={field}
        label={label}
        options={options}
        propsFromForm={propsFromForm}
        textClassName={textClassName}
        {...props}
      />
    </FieldContainer>
  );
};

Dropdown.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Dropdown;
