// Highly inspired from the React docs
// https://beta.reactjs.org/reference/react/Component#catching-rendering-errors-with-an-error-boundary
import React from 'react';
import PropTypes from 'prop-types';

export class IntlErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App

    // We cannot rely on Bugsnag for now since Bugsnag is not loaded
    // into bridged components where this might be used

    // TODO: FEP - Going to leave this as a console.warn for a moment and come back to this
    //  with a more elegant solution.  There is a potential to pass in additional props to
    //  report to bugsnag or not depending on the route and context data we get from
    //  <McIntlProvider />
    console.warn(error, errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

IntlErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
};
