// TODO:
// need to assess how we handle dates in a global sense in order
// to come up with the most sensible API, e.g. if all campaign dates
// are formatted the same, maybe we export discrete formatting functions
// for different usages/contexts.

const DEFAULT_OPTS = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const DAY_IN_SECONDS = 86400;
export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = SECOND_IN_MS * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;

export const DATE_FORMATS = {
  MONTH_DAY: {
    curFormat: 'LLLL do',
    yearFormat: 'LLLL do, y',
  },
  DAYNAME_MONTH_DAY: {
    curFormat: 'EEE, LLLL do',
    yearFormat: 'EEE, LLLL do, y',
  },
  TIME: 'h:mm aa',
};

/**
 * For timezone labels in the US, we only want to use
 * short abbreviations and for Intl. we want to use full names.
 * according to our style guide - https://styleguide.mailchimp.com/grammar-and-mechanics/#header-4-time
 *
 * @param {String} timezoneId
 * @return {String} timeZoneDisplay
 */
export const getTimezoneLabel = (timezoneId, date = new Date()) => {
  // get timeZone and return abbreviations if US based, full name if International
  const timeZoneObject = Intl.DateTimeFormat('en-US', {
    timeZone: timezoneId,
    timeZoneName: 'long',
  })
    .formatToParts(date)
    .find((object) => object.type === 'timeZoneName');

  let timeZoneDisplay;

  switch (timeZoneObject.value) {
    case 'Pacific Standard Time':
    case 'Pacific Daylight Time':
      timeZoneDisplay = 'PT';
      break;
    case 'Mountain Standard Time':
    case 'Mountain Daylight Time':
      timeZoneDisplay = 'MT';
      break;
    case 'Central Standard Time':
    case 'Central Daylight Time':
      timeZoneDisplay = 'CT';
      break;
    case 'Eastern Standard Time':
    case 'Eastern Daylight Time':
      timeZoneDisplay = 'ET';
      break;
    default:
      timeZoneDisplay = timeZoneObject.value;
  }
  return timeZoneDisplay;
};
/**
 * For using browser Intl API, the timezone needs to be IANA compliant to be use to set the date formats
 * @param {String} tz from defaults settings
 * @return {Boolean} whether the timezone string is valid
 */
export const isValidTimeZone = (tz) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    // log es6 isValidTimeZone call
    return false;
  }
};

/**
 *
 * @param {Date} date
 * @param {Object} formatObj format
 * @return {String} date format
 */
export function getFormatYearFormat(date, { curFormat, yearFormat }) {
  const today = new Date();
  const todayYear = today.getFullYear();
  return date.getFullYear() !== todayYear ? yearFormat : curFormat;
}

/**
 * The thinnest wrapper on top of Date.prototype.toLocaleString.
 * Accepts a object of options which it proxies to said method.
 *
 * @param {Date|String|Number} date The date to format
 * @param {Object} opts Configuration options
 * @return {String} Formatted date
 */
export function formatDate(date, opts = DEFAULT_OPTS) {
  return new Date(date).toLocaleString('en-US', opts);
}

/**
 * A function to determine whether a date is in the future or not.
 *
 * @param {Date|String|Number} dateOrDateLike The date or date like instance
 * @return {Boolean} Whether the date is in the future
 */
export function isFutureDate(dateOrDateLike) {
  const date =
    typeof dateOrDateLike === 'string'
      ? new Date(dateOrDateLike)
      : dateOrDateLike;

  return date > Date.now();
}

/**
 * A function that gets the total number of days within between two dates.
 *
 * @param {Date|String|Number} date1 closest to the present day to format
 * @param {Date|String|Number} date2 farthest to the present day to format
 * @return {Number} total amount of days
 */
export const getTotalAmountOfDaysBetweenTwoDates = (date1, date2) => {
  const res = Math.abs(new Date(date1) - new Date(date2)) / SECOND_IN_MS;
  return Math.floor(res / DAY_IN_SECONDS);
};

/**
 * Utility to convert a date object _to_ a user's timezone or _from_ a user's timezone
 *
 * @param {Number} userTimezoneOffset is the timezone offset from the User's settings
 * @param {Date} date a date to parse
 * @param {Boolean} useDateForOffset whether to use today or date's timezone offset?
 * @param {Boolean} isFromUserTimezone is the date to pare in the user's timezone?
 * @returns {Date} date object adjusted for user timezone
 */
export const getTimezoneOffsetDate = (
  userTimezoneOffset,
  date,
  useDateForOffset = true,
  isFromUserTimezone = false,
) => {
  const today = new Date();
  // If the user has a timezone specified, adjust the date object for string formatting by dojo locale
  if (userTimezoneOffset !== null && !isNaN(userTimezoneOffset)) {
    // This variable can be removed/replaced when channels.campaign_calendar_timezones flag is removed
    const timezoneOffsetDate = useDateForOffset
      ? date.getTimezoneOffset()
      : today.getTimezoneOffset();

    if (isFromUserTimezone) {
      date = new Date(
        date.getTime() +
          timezoneOffsetDate * MINUTE_IN_MS * -1 -
          userTimezoneOffset * HOUR_IN_MS,
      );
    } else {
      date = new Date(
        date.getTime() -
          timezoneOffsetDate * MINUTE_IN_MS * -1 +
          userTimezoneOffset * HOUR_IN_MS,
      );
    }
  }

  return date;
};

/**
 * MCUserDate creates a JS date converted for a user's timezone for display and comparison
 * once we return date objects with their own tzOffset
 * the date can be formatted with a correct offset for display
 * @param {String} dateStr date represented as a ISO8601 string, as UTC, without a timezone
 * @param {Number} tzOffset the current(today) timezone offset of the User's default timezone
 * @return {Date}
 */
export class MCUserDate extends Date {
  userTimezoneOffset = 0;
  constructor(dateStr, tzOffset = 0, useToday = true) {
    if (typeof dateStr !== 'string') {
      throw Error('MCUserDate expects a string');
    }

    // return new Date that is offset by the correct timezone - and can be formatted for display
    const date = new Date(dateStr);
    const today = new Date();
    const timezoneOffsetDate = useToday
      ? today.getTimezoneOffset()
      : date.getTimezoneOffset();
    const convertedDate =
      date.getTime() -
      timezoneOffsetDate * MINUTE_IN_MS * -1 +
      tzOffset * HOUR_IN_MS;
    super(convertedDate);
    this.userTimezoneOffset = tzOffset;
  }
  // for some campaigns we are returning a specific dateTime in UTC for scheduling
  convertToUTC() {
    return new Date(
      this.getTime() +
        this.getTimezoneOffset() * MINUTE_IN_MS * -1 -
        this.userTimezoneOffset * HOUR_IN_MS,
    );
  }
}
