import React from 'react';
import PropTypes from 'prop-types';

import { Textarea } from '@mc/wink';
import HelpTextLabel from './HelpTextLabel';

function TextareaField({
  field,
  label,
  // only gets passed when being rendered by a public-facing form
  propsFromForm = {},
  textClassName,
  ...props
}) {
  return (
    <div>
      <Textarea
        label={label || propsFromForm.label}
        {...propsFromForm}
        {...props}
        id={propsFromForm.name}
        style={{ resize: 'none' }}
      />
      {field.helpTextEnabled && field.helpText && (
        <HelpTextLabel helpText={field.helpText} className={textClassName} />
      )}
    </div>
  );
}

TextareaField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object.isRequired,
  textClassName: PropTypes.string,
};

export default TextareaField;
