import React from 'react';
import PropTypes from 'prop-types';
import FieldContainer from '../BaseComponent/FieldContainer';
import RadioGroupField from '../BaseComponent/RadioGroupField';

const Radio = ({
  field,
  destination,
  label,
  propsFromForm,
  textClassName,
  ...props
}) => {
  const options = destination.choices.map((choice) => {
    return {
      id: `${field.id}${choice}`,
      name: choice,
      value: choice,
      label: choice,
    };
  });

  return (
    <FieldContainer>
      <RadioGroupField
        field={field}
        label={label}
        options={options}
        propsFromForm={propsFromForm}
        textClassName={textClassName}
        {...props}
      />
    </FieldContainer>
  );
};

Radio.propTypes = {
  destination: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  propsFromForm: PropTypes.object,
  textClassName: PropTypes.string,
};

export default Radio;
