import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ClusterLayout } from '@mc/wink';
import styles from './HeaderImage.less';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const HeaderImage = ({ imageUrl, imageSize }) => {
  if (!imageUrl) {
    return null;
  }

  if (!SIZES[imageSize]) {
    imageSize = SIZES.large;
  }

  return (
    <ClusterLayout justifyContent="center" gap={0}>
      <img
        src={imageUrl}
        className={classnames(styles[imageSize])}
        alt="Header"
      />
    </ClusterLayout>
  );
};

HeaderImage.propTypes = {
  imageSize: PropTypes.oneOf(Object.keys(SIZES)),
  imageUrl: PropTypes.string,
};

export { SIZES };

export default HeaderImage;
